<template>
    <b-overlay :show="showLoader">
        <b-card class="reserve-fund mb-0">
            <b-row class="mt-2" align-v="center">
                <b-col cols="12" lg="5" class="mt-1 mt-lg-0">
                    <Select
                        v-model="filter.unit"
                        :options="units"
                        label="naziv"
                        placeholder="Enota"
                        @change="loadSelection"
                        :customDropdownLabelFn="customLabelFunction"
                        :customSelectedLabelFn="customLabelFunction"
                    />
                </b-col>
                <b-col cols="12" lg="3" class="mt-1 mt-lg-0">
                    <rounded-button class="w-100" @click="loadSelection"
                    >PRIKAŽI <fa icon="search"
                    /></rounded-button>
                </b-col>
            </b-row>
            <div>
                <h3 class="mt-2 font-top-row">
                    Odčitki
                </h3>
            </div>
            <b-tabs v-model="tabIndex">
                <b-tab title="Toplotna energija">
                    <h1 class="text-secondary font-title">Toplotna energija</h1>
                    <my-portal-table class="mt-1" :data="energy_data" :fields="fields">
                        <template #cell(actions)="data">
                            <a @click="downloadDoc(data.item)" class="d-flex w-100 justify-content-end">
                                <feather-icon class="mr-1" size="1.5x" icon="DownloadIcon" />
                            </a>
                        </template>
                    </my-portal-table>
                </b-tab>
                <b-tab title="Topla voda">
                    <h1 class="text-secondary font-title">Topla voda</h1>
                    <my-portal-table class="mt-1" :data="hot_water_data" :fields="fields">
                        <template #cell(actions)="data">
                            <a @click="downloadDoc(data.item)" class="d-flex w-100 justify-content-end">
                                <feather-icon class="mr-1" size="1.5x" icon="DownloadIcon" />
                            </a>
                        </template>
                    </my-portal-table>
                </b-tab>
                <b-tab title="Hladna voda">
                    <h1 class="text-secondary font-title">Hladna voda</h1>
                    <my-portal-table class="mt-1" :data="cold_water_data" :fields="fields">
                        <template #cell(actions)="data">
                            <a @click="downloadDoc(data.item)" class="d-flex w-100 justify-content-end">
                                <feather-icon class="mr-1" size="1.5x" icon="DownloadIcon" />
                            </a>
                        </template>
                        <!--                        <template #cell(stanje_terjatev)="row">
                            <span>{{$convertFloat(row.item.rezervni_fakturirano - row.item.rezervni_placano) }}</span>
                        </template>
                        <template #cell(stanje_sredstev)="row">
                            <span>{{$convertFloat(row.item.rezervni_placano - row.item.rezervni_poraba) }}</span>
                        </template>-->
                    </my-portal-table>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BOverlay, BRow, BCol, BCard, BTab, BTabs } from 'bootstrap-vue'
    import Select from '../../Components/Select'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    import MyPortalTable from '@/views/Components/MyPortalTable'
    export default {
        components: {
            MyPortalTable,
            BOverlay,
            BRow,
            BCol,
            Select,
            BCard,
            RoundedButton,
            BTab,
            BTabs
        },
        data() {
            return {
                showLoader: false,
                filter: { unit: '' },
                tabIndex: 0,
                objects: [],
                units:[],
                energy_data: [],
                hot_water_data: [],
                cold_water_data: [],
                fields: [
                    { key: 'naziv', label: 'Naziv', sortable: true, class: 'text-left' },
                    { key: 'date_odcitki', label: 'Datum', sortable: true, class: 'text-left' },
                    { key: 'actions', label: '', sortable: false, class: 'text-left'}
                    /*{ key: 'terjano', label: 'Terjano', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) } }*/
                ],
                dataVoluntary: []
            }
        },
        methods: {
            customLabelFunction(unit) {
                return `${unit.naziv}`
            },
            async loadData() {
                this.showLoader = true
                this.$scrollToTop()
                try {
                    //await this.loadObjects()
                    await this.loadUnits()
                } catch (error) {
                    this.$printError(
                        `Prišlo je do napake pri nalaganju podatkov\n${error.message}`
                    )
                } finally {
                    this.showLoader = false
                }
            },
            async loadSelection() {
                this.showLoader = true
                try {
                    await this.loadReadingsHeatingEnergy()
                    await this.loadReadingsHotWater()
                    await this.loadReadingsColdWater()
                } catch (error) {
                    this.$printError(
                        `Prišlo je do napake pri nalaganju podatkov\n${error.message}`
                    )
                } finally {
                    this.showLoader = false
                }
            },
            async loadObjects() {
                const res = await this.$http.get(
                    '/api/iiportal-user/v1/e_manager/objekti'
                )
                this.objects = res.data
                this.objects.sort((a, b) => {
                    if ((a.ulica === b.ulica && parseInt(a.hisna_stevilka) < parseInt(b.hisna_stevilka)) || (a.ulica < b.ulica)) return -1
                    else if (a.ulica > b.ulica) return 1
                    else return 0
                })

                if (this.objects.length > 0)  this.filter.unit = this.objects[0]

            },
            async loadReadingsHeatingEnergy() {
                const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/icounters/${this.filter.unit.sifra}/01`)
                this.energy_data = res.data
                this.energy_data.forEach((x, index) => {
                    this.energy_data[index].date_odcitki = `${x.obracun_mesec  }/${  x.obracun_leto}`
                })
            },
            async loadReadingsHotWater() {
                const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/icounters/${this.filter.unit.sifra}/02`)
                this.hot_water_data = res.data
                this.hot_water_data.forEach((x, index) => {
                    this.hot_water_data[index].date_odcitki = `${x.obracun_mesec  }/${  x.obracun_leto}`
                })
            },
            async loadReadingsColdWater() {
                const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/icounters/${this.filter.unit.sifra}/03`)
                this.cold_water_data = res.data
                this.cold_water_data = res.data
                this.cold_water_data.forEach((x, index) => {
                    this.cold_water_data[index].date_odcitki = `${x.obracun_mesec  }/${  x.obracun_leto}`
                })
            },
            async  loadUnits() {
                const res = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                
                this.units = []
                res.data.forEach((x) => {
                    if (x.vrsta === 'L') {
                        if (res.data.find((element) => element.inventarna_stevilka === x.inventarna_stevilka && element.vrsta !== x.vrsta)) {
                            x.vrsta = 'L+U'
                            this.units.push(x)
                        } else {
                            this.units.push(x)
                        }
                    } else if (res.data.find((element) => element.inventarna_stevilka === x.inventarna_stevilka && element.vrsta !== x.vrsta) === undefined) {
                        this.units.push(x)
                    }
                })

                this.units.sort((a, b) => {
                    if ((a.ulica === b.ulica && parseInt(a.hisna_stevilka) < parseInt(b.hisna_stevilka)) || (a.ulica < b.ulica)) return -1
                    else if (a.ulica > b.ulica) return 1
                    else return 0
                })

                if (this.units.length > 0)  this.filter.unit = res.data[0]
            },
            downloadDoc(doc) {
                const thisIns = this
                this.showLoader = true

                let documentType = ''
                if (this.tabIndex === 0) { documentType = '01' } else if (this.tabIndex === 1) { documentType = '02' } else if (this.tabIndex === 2) { documentType = '03' }

                this.$http({
                    url: `/api/iiportal-user/v1/attachements/icounters/${this.filter.unit.sifra}/${documentType}/${doc.id}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `${doc.naziv}.${doc.format.toLowerCase()}`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                    })
            }
        },
        async mounted() {
            await this.loadData()
            await this.loadSelection()
        }
    }
</script>

<style>
.reserve-fund .font-top-row {
    background: #72a5d8;
    font-weight: 600;
    color: white;
    padding: 10px;
}
.reserve-fund {
    border-radius: 10px !important;
}
.reserve-fund .font-title {
    font-weight: bold;
    color: #72a5d8 !important;
}
.reserve-fund .font {
    font-weight: bold;
}
</style>
